import logo from './logo.svg';
import './App.css';
import Main from './main/Main';

function App() {
  return (
    <>
    <Main/>
    </>
  );
}

export default App;
