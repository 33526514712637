import { useEffect, useState } from 'react'
// import './css/index.css'
import img from './img/logo.webp'
import { Link } from 'react-router-dom'
import { RxHamburgerMenu } from "react-icons/rx"
import { navbar } from './data'
const Navbar =()=>{
    const [icon,setIcon]=useState(false)
    const toggle = ()=>{
        setIcon(!icon)
        console.log('l');
    }
    const [hide,sethide]=useState(false)
    useEffect(()=>{
        window.addEventListener('scroll',()=>{
            // console.log(window.scrollY);
            if(window.scrollY>=200) sethide(true)
            if(window.scrollY<=190) sethide(false)
        })
    },[])

    return(
        <>
        <nav className={`${hide?"hide":0}`}>
            <sub className='sub1'>
                <Link to='/' style={{textDecoration:'none',color:'inherit'}}>
                    <img src={navbar.logo} className="home-logo" alt="" />
                    </Link>
                <div className="logo-name"> Arshath
                <br style={{height:'10px'}} />Enterprises</div>
            </sub>
            <sub className='sub2'>
                <RxHamburgerMenu size='30px' color='white' className='menu-icon' onClick={toggle}/>
                {/* <div className="shadow"></div> */}
                <div className={`sidebar ${icon ? "show-nav":"hide-nav"}`}  onClick={toggle}>
                    <li ><button  onClick={toggle} style={{cursor:'pointer',border:'none',margin:'0 0 0 auto'}}  >x</button></li>
                    <li><Link to='/' style={{textDecoration:'none',color:'inherit'}}>Home</Link></li>
                    <li><Link to='/about' style={{textDecoration:'none',color:'inherit'}}>About</Link></li>
                    <li><Link to='/portfolio' style={{textDecoration:'none',color:'inherit'}}>Portfolio</Link></li>
                    {/* <li><Link to='/services' style={{textDecoration:'none',color:'inherit'}}>Service</Link></li> */}
                    <li><Link to='/contact' style={{textDecoration:'none',color:'inherit'}}>Contact</Link></li>
                </div>
                <ul>
                    <li><Link to='/' style={{textDecoration:'none',color:'inherit'}}>Home</Link></li>
                    <li><Link to='/about' style={{textDecoration:'none',color:'inherit'}}>About</Link></li>
                    <li><Link to='/portfolio' style={{textDecoration:'none',color:'inherit'}}>Portfolio</Link></li>
                    {/* <li><Link to='/services' style={{textDecoration:'none',color:'inherit'}}>Service</Link></li> */}
                    <li><Link to='/contact' style={{textDecoration:'none',color:'inherit'}}>Contact</Link></li>
                    {/* <li></li> */}
                    {/* <li>Service</li>
                    <li>Contact</li> */}
                </ul>
            </sub>
        </nav>
        {/* <main>

        </main> */}

        </>
    )
}
export default Navbar;