import { BrowserRouter, Route, Routes } from "react-router-dom"
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Portfolio from "./Portfolio";
import End from "./End";
import Service from "./Service";
import ContactUs from "./ContactUs";
import { whatsAppUrl } from "./data";
import { useEffect, useState } from "react";
import PopupForm from "./popup/PopupForm";

const Main=()=>{
    const [toggle,setToggle] = useState(false)
    useEffect(() => {
      popup()
    
      return () => {
      popup()
      }
    }, [])

    function popup(){
      const x = setTimeout(() => {
        setToggle(!toggle)
        console.log(0);
      }, 
      5000);
      console.log(0);
    }   

    return(
        <>
        <BrowserRouter>
        {toggle&&<PopupForm tog={setToggle}/>}
       <div className="wrapper">
        <a href={whatsAppUrl}><div className="float-btn">
            
            </div></a>
       <Navbar/>
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/portfolio" element={<Portfolio/>}/>
            {/* <Route path="/services" element={<Service/>}/> */}
            <Route path="/Contact" element={<ContactUs/>}/>
        </Routes>
        <End/>
       </div>
        </BrowserRouter>
        </>
    )
}
export default Main;