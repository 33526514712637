import img from './img/cli.jpeg'
import img1 from './img/cli1.jpeg'
import img2 from './img/cli2.jpeg'
import img3 from './img/cli3.jpeg'
import img4 from './img/cli4.jpeg'
import img5 from './img/cli5.jpeg'
import img6 from './img/cli6.jpeg'
import img7 from './img/cli7.jpeg'
import img8 from './img/cli8.jpeg'
import img9 from './img/cli9.jpeg'
import img10 from './img/cli10.jpeg'
import img11 from './img/cli11.jpeg'
import logo from './img/logo.jpg'
import whatsapplogo from './img/whatsapp-logo.png'
// import img2 from './img/cli2.jpeg'
// import img2 from './img/cli2.jpeg'
// import img2 from './img/cli2.jpeg'
export const data = {
    hero:{
        heading1:`Seamless Style.`,
        heading2:` Sophisticated Settings.`,
        content:"Welcome to Arsath Enterprises"
    },
    secondInHome:` where we transform spaces into extraordinary expressions of style and functionality. As a premier interior design firm, we are dedicated to creating captivating and personalized environments that reflect the unique tastes and lifestyles of our clients.`,
    founder1:`At Arsath Enterprises, we believe that every space has the potential to become a work of art. Our team of experienced and talented designers is passionate about bringing your vision to life, whether you're looking to revitalize a single room or embark on a complete home or office makeover. We understand that your surroundings play a crucial role in enhancing your well-being and productivity, and we are committed to designing spaces that not only look stunning but also function seamlessly.`,
    founder2:`What sets us apart is our commitment to collaboration. We work closely with our clients to understand their preferences, needs, and aspirations. Your input is invaluable, and we believe that the best designs emerge from a partnership between our expertise and your unique perspective. Whether you have a clear vision in mind or need guidance to discover your style, we are here to guide you through the creative process.`,
    founder3:`Our portfolio showcases various projects, from contemporary and minimalist designs to classic and timeless aesthetics. We pride ourselves on staying abreast of the latest design trends and incorporating innovative solutions to ensure that your space is not only beautiful but also practical and sustainable.`,
    ourApproach1:`At Arsath Enterprises, we emphasize quality craftsmanship and attention to detail. We source the finest materials and collaborate with skilled artisans and vendors to deliver interiors that stand the test of time. From custom furniture pieces to curated color palettes, every element is meticulously chosen to create a harmonious and inviting atmosphere.`,
    ourApproach2:`Whether you're a home owner, business owner, or real estate professional, we are dedicated to exceeding your expectations. Our mission is to turn your dreams into reality, one carefully curated space at a time. Discover the transformative power of exceptional design with Arsath Enterprises where your space becomes a masterpiece.`,
    whyUs:`Why US`,
    card1:{
        title:`Expertise and Experience`,
        content:`With years of experience in the interior design industry, our team at Arsath Enterprises brings a wealth of expertise to every project. From residential to commercial spaces, we have successfully transformed a diverse range of environments, creating stunning and functional designs that stand the test of time`
    },
    card2:{
        title:`Tailored Solutions`,
        content:`We understand that each client is unique, and so are their preferences and requirements. At Arsath Enterprises, we pride ourselves on delivering tailored solutions that align with your style and needs. Our collaborative approach ensures that your vision is at the forefront of every design decision.`
    },
    card3:{
        title:`Attention to Detail`,
        content:`The beauty of a well-designed space lies in the details. Our designers pay meticulous attention to every element, from the selection of materials to the placement of furnishings. This commitment to detail results in spaces that are not only aesthetically pleasing but also harmonious and functional.`
    },
    card4:{
        title:`Innovative Design Concepts`,
        content:`Keeping abreast of the latest design trends and innovations is a cornerstone of our approach. Arsath Enterprises is dedicated to bringing fresh and creative ideas to the table, ensuring that your space reflects contemporary design while remaining timeless.`
    },
    address:{

    }

}

export const projectImg = [img,img1,img2,img3,img4,img5,img7,img6,img8,img9,img10,img11]
export const navbar = {logo:logo}
export const url = `https://ar-enterprises.onrender.com/sendEmail`
export const number = `tel:+91 8608310797`
export const whatsAppUrl=`https://wa.me/918608310797`
export const whtapplogo = whatsapplogo

// exports default data