import Animation  from './Animation'
// import './css/about.css'
// import img from './img/flight-bg.jpg'
// import p1 from './img/portfolio1.jpg'
// import p2 from './img/portfolio2.jpg'
// import p3 from './img/portfolio3.jpg'
// import p4 from './img/portfolio4.jpg'
// import p5 from './img/portfolio5.jpg'
// import p6 from './img/portfolio6.jpg'
// import p7 from './img/portfolio7.jpg'
// import p8 from './img/portfolio8.jpg'
// import p9 from './img/portfolio9.jpg'
// import p10 from './img/portfolio10.jpg'
// import p11 from './img/portfolio11.jpg'
// import p12 from './img/portfolio12.jpg'
// import p13 from './img/portfolio13.jpg'
// import p14 from './img/portfolio14.jpg'
// import p15 from './img/portfolio15.jpg'
import { projectImg } from './data'
// const x = [...projectImg,p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,p14,p15]
const x = [...projectImg]
const Portfolio = () =>{
    return(
        <>
        <main className='portfolio'>
            <h1 style={{}}>
                
<Animation>
Featured Projects
</Animation>
            </h1>
            <figure className='portfolio-img-grid' style={{padding:"30px 0"}}>
                {/* li.li.li${u$}*15 */}
                {x.map((e,i)=>{
                    return(
                        <>
                        <li className="portfolio-grid" key={i}><img src={e} alt="" /></li></>
                    )
                })}
                {/* <li className="portfolio-grid portfolio1">content1</li>
                <li className="portfolio-grid portfolio2">content2</li>
                <li className="portfolio-grid portfolio3">content3</li>
                <li className="portfolio-grid portfolio4">content4</li>
                <li className="portfolio-grid portfolio5">content5</li>
                <li className="portfolio-grid portfolio6">content6</li>
                <li className="portfolio-grid portfolio7">content7</li>
                <li className="portfolio-grid portfolio8">content8</li>
                <li className="portfolio-grid portfolio9">content9</li>
                <li className="portfolio-grid portfolio10">content10</li>
                <li className="portfolio-grid portfolio11">content11</li>
                <li className="portfolio-grid portfolio12">content12</li>
                <li className="portfolio-grid portfolio13">content13</li>
                <li className="portfolio-grid portfolio14">content14</li> */}
                {/* <li className="portfolio-grid portfolio15">content15</li> */}

            </figure>
        </main>
        </>
    )
}
export default Portfolio