import useEmblaCarousel from 'embla-carousel-react';
import img from '../img/female.webp'
import img2 from '../img/flight-bg.jpg'
import sl1 from '../img/slider1.jpg'
import sl2 from '../img/slider2.jpg'
import sl3 from '../img/sl3.jpg'
import sl4 from '../img/sl4.jpg'
import sl5 from '../img/sl5.jpg'
import img3 from '../img/section7.webp'
import img4 from '../img/svg.png'

// import './slider.css'
import Autoplay from 'embla-carousel-autoplay';
import {stopOnFocus} from 'embla-carousel-autoplay';
import { useCallback } from 'react';
import {FaArrowRight } from 'react-icons/fa'
import {FaArrowLeft } from 'react-icons/fa'
import { projectImg } from '../data';
// const immg = [sl4,sl1,sl3,sl2,sl5]
const immg = [1,2,3,4,5,6,7,8,9,10,11]

const Slider=()=>{
    const [emblaRef,emblaApi] = useEmblaCarousel({
         loop: true ,
        autoplay:true, 
         autoplayInterval: 0,
         plugin:[Autoplay()],
         stopOnFocus:true}, []


         ,[Autoplay()])
         const scrollPrev = useCallback(() => {
            if (emblaApi) emblaApi.scrollPrev()
          }, [emblaApi])
        
          const scrollNext = useCallback(() => {
            if (emblaApi) emblaApi.scrollNext()
          }, [emblaApi])
        
    return(
        <>
       
       <div className='slider-grid'>
       <button  onClick={scrollPrev} className='slider-prev'><FaArrowLeft/></button>
        <div className="embla" ref={emblaRef}>
      <div className="embla__container" ref={emblaRef} >
        {immg.map((e,i)=>{
            return(
                <>
                {/* <div className="embla__slide" key={i}><img src={e} alt="" /></div> */}
                <div className="embla__slide" key={i}>
              <img
                className="embla__slide__img"
                src={projectImg[i]}
                alt="Your alt text"
              />
            </div>
                </>
            )
        })}
      </div>
    </div>
    <button  onClick={scrollNext} className='slider-next'><FaArrowRight/></button>

       </div>
        </>
    )
}
export default Slider;