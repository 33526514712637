import {motion,useAnimate,useInView} from 'framer-motion'
import { useEffect } from 'react';


const Animation = ({children,d}) =>{
    useEffect(()=>{},[])

    return(
        <>
        <motion.div
        initial={{y:1000}}
        animate={{y:0}}
        transition={{delay:d , duration:1}}  
        style={{}}
        >
        {children}
        </motion.div>
        </>
    )
}
export default Animation;