import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import './popupForm.css'
import SendMessage from '../components/SendMessage'
import { ImCancelCircle } from "react-icons/im";
const style ={
    position:'fixed',
    left:'50%',
    top:'50%',
    transform:'translate(-50%,-50%)',
    // backgroundColor:'#fff',
    zIndex:'1000',
    // border:"1px solid #aaa",
    // padding:'10px',
    width:'fit-content',
    transition:'all .5s ease .2s'
}
const overlay ={
    position:'fixed',
    left:0,
    right:0,
    top:0,
    bottom:0,
    zIndex:'1000',
    backgroundColor:'rgb(0,0,0,.7)',
    backdropFilter:'blur(2px)',
    transition:'all .5s'
}
const PopupForm = ({tog}) => {
  
    const [xe,setX] = useState(false)
    useEffect(()=>{
    if(tog){
            let x = setTimeout(()=>{
                setX(!xe)
                console.log('][]',xe);
            },1000)
        }
    },[])
  return ReactDOM.createPortal(
    
        <>
        <div style={{...overlay,opacity:xe?1:0}}></div>
        <div className="modal" style={{...style,opacity:xe?1:0}}>
          {/* <div>  Enquire  */}
          <button className='cancel' onClick={()=>tog(false)}>🗙</button>
           {/* </div> */}
            {/* <input type="text" placeholder="enter" /><br />
            <input type="text" placeholder="enter" /><br />
            <input type="text" placeholder="enter" /><br />
            <input type="text" placeholder="enter" /><br />
            <input type="submit" name="" id="" /> */}
            <SendMessage/>
    
        </div>
        </>,
      document.getElementById('portal')
  )
  
}

export default PopupForm