import { useAnimation, useInView, useAnimationw,motion } from "framer-motion"
import { useEffect, useRef } from "react"

const Inview=({children,classname,delay,animation})=>{
    const ref = useRef(null)
    const isInView = useInView(ref , {once:true})
    const controls = useAnimation({opacity:0})
    useEffect(()=>{
        if(isInView){
            controls.start(animation.final)
            console.log("isInView");
        }
    },[isInView])

    return(
        // <>
        <motion.div
        // style={{height:'max-content'}}
        ref={ref}
        variants={animation}
        transition={{
            delay:delay,
            duration:1
        }}
        initial={animation.initial}
        animate={controls}
        className={classname }
        style={{overflow:"hidden"}}
        >
        {children}    
        </motion.div>   

        // {/* </> */}
    )
}
export default Inview   