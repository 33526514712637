// import './css/contactUs.css';
import { useState } from 'react';
import Animation from './Animation';
import * as Yup from 'yup'
import axios from 'axios';
import { url } from './data';
const ContactUs = () => {
  const [formData,setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    subject: ''
  })
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const validationSchema = Yup.object().shape({
    fname: Yup.string().required('First Name is required'),
    lname: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required').min(10,'Must be 10 digits').max(10,'Must be 10 digits'),
    subject: Yup.string().required('Subject is required')
  });

  const handleSubmit = async(e) =>{
    e.preventDefault();
    console.log(formData);
    try {
      setLoading(true)
      await validationSchema.validate(formData,{ abortEarly: false })
      setFormData(prv => prv={
    fname: '',
    lname: '',
    email: '',
    phone: '',
    subject: ''})
      console.log('Form submitted successfully:', formData);
      const result = await axios.post(url,{...formData}) 
      console.log(result);
      setEmailSent(true);
    } catch (validationErrors) {
      const errors ={}
      validationErrors.inner.forEach(error =>{
        errors[error.path] = error.message
      })
      setErrors(errors);
      console.log(validationErrors[0]);
    }
    finally{
      setLoading(false);
      setTimeout(()=>{setEmailSent(false)},5000)
    }
  }

  const handleChamge = (e) =>{
    const {name,value} = e.target
    setFormData({
      ...formData,
      [name]:value
    })
    if(errors[name]){
      setErrors({...errors,[name]:""})
    }
  }

    return (
      <>
      <div className="brownie">
      <h1 className="brow"><Animation d={.3}>WE WOULD LOVE</Animation></h1>
                <h1 className="brow" style={{marginBottom:'30px'}}><Animation d={.5}>TO MEET YOU</Animation></h1>
                {/* <p className="brown">Email · intorinteriors@gmail.com</p> */}
                <p className="brown">Phone : 8608310797 , 9751172355</p>
                <p className="brown">Address : 355/1, Sri Kannapiram </p>
                <p className="brown"> Complex , Ayyavu Devar Nagar,</p>
                <p className="brown"> Kannanathal , IyarBanglaw,</p>
                <p className="brown">  Madurai-14</p>
                
      </div>
      <div class="black">
      <p className="blackie">Contact Us</p>

      </div>
      
<div className='form-cont'>
<div class="forms">

<div class="flexcontainer">
      <div class="form-group">
        <label for="fname">First Name*</label>
        <input value={formData.fname} type="text" class="text2" id="fname" name="fname" onChange={handleChamge} />
        <p style={{margin:0,color:'#a00',height:'1ch'}}> {errors.fname}</p>
      </div>
      <div class="form-group">
        <label for="lname">Last Name*</label>
        <input value={formData.lname} type="text"  class="text2" id="lname" name="lname" onChange={handleChamge}/>
        <p style={{margin:0,color:'#a00',height:'1ch'}}>{errors.lname}</p>
      </div>
    </div>

    <div class="flexcontainer">
      <div class="form-group">
        <label for="fname">Email*</label>
        <input value={formData.email} type="text" class="text2" id="fname" name="email" onChange={handleChamge} />
        <p style={{margin:0,color:'#a00',height:'1ch'}}>{errors.email}</p>
      </div>
      <div class="form-group">
        <label for="lname">Phone*</label>
        <input value={formData.phone} type="text" class="text2" id="lname" name="phone" onChange={handleChamge}/>
        <p style={{margin:0,color:'#a00',height:'1ch'}}>{errors.phone}</p>
      </div>
    </div>
    

    <div class="flexcontainer">
      <div class="form-group">
        <label for="fname">Subject*</label>
        <input value={formData.subject} type="text" id="fname" class="text2" name="subject" onChange={handleChamge}/>
        <p style={{margin:0,color:'#a00',height:'1ch'}}>{errors.subject}</p>
      </div>
      
    </div>
     
    <div class="form-group ">
    <center>
                        <button className="button11" onClick={handleSubmit}>{loading?"loading...":`${emailSent?'Sended':'Send'}`}</button>
                    </center>
    </div>

</div>
  
</div>







      </>
 
      
    );
 }
 
 export default ContactUs;