import { useEffect, useRef, useState } from 'react';
import Animation from './Animation';
import Inview from './Inview';
import HorizontalBtn from './components/HorizontalBtn';
// import './css/about.css'

import img from './img/female.webp'
import {FaArrowRight } from 'react-icons/fa'
import Slider from './components/Slider';
import { data, projectImg } from './data';
const x = [img,2,img,4,5,6,7,8,9]
const slideUp = {
    initial:{y:100}
    ,final:{y:0}
}
const About = () =>{
    const [images] = useState(x); // Your array of image URLs
    const sliderRef = useRef(null);

    // useEffect(() => {
    //     const slider = sliderRef.current;
    
    //     const handleScroll = () => {
    //       // Check if we've reached the end of the slider
    //       if (slider.scrollLeft + slider.clientWidth >= slider.scrollWidth) {
    //         // If so, jump back to the beginning of the slider
    //         slider.scrollTo({
    //           left: 0,
    //           behavior: 'smooth'
    //         });
    //       }
    //     };
    
    //     // Attach scroll event listener
    //     slider.addEventListener('scroll', handleScroll);
    
    //     return () => {
    //       // Cleanup: Remove scroll event listener
    //       slider.removeEventListener('scroll', handleScroll);
    //     };
    //   }, []);
      
    return(
        <>
        <main>
            <section className="about-sec1">
                <h1 style={{overflow:'hidden'}}>
                    <Animation>
                    {/* Intor Interiors and Contracting */}
                    {data.hero.content}
                    </Animation>
                </h1>
                <p>
                    {/* Intor Interiors and Contracting is a comprehensive design and contracting firm,
                     specializing in residential and commercial interiors. 
                     We offer total interior solutions, including space planning,
                     turnkey services, renovations, sourcing of interior products and decorative artifacts,
                      as well as wall materials and fabrics. */}
                      {data.secondInHome}
                      </p>
            </section>
            <div className="about-us">
                About Us
            </div>
            <section className='about-sec2'>
                <article>
                {/* As the founder of Intor Interiors and contracting, I, Lizabeth Joseph, have always been passionate about transforming spaces and
                 creating beautiful interiors. Prior to establishing Intor in 2015, I honed my skills and gained valuable experience working with 
                 renowned companies such as Woodlife Interiors, Donna Rossi Modular Kitchen and Wardrobes, and IKian Furniture's. */}
                 {data.founder1}
                 <br />
                 <br />
                 {data.founder2}
    {/* After years of dedication and building expertise in the industry, I realized that I wanted to work for myself and bring my unique
     vision to life. I wanted the freedom to explore my creative ideas and create stunning interiors without limitations. This desire
      to forge my own path led me to start the brainchild of Intor Interiors. */}
    <br />
    <br />
                 {data.founder3}
    {/* The name "Intor" itself is derived from the word "interiors," symbolizing our unwavering focus on creating 
    exceptional interior spaces. Our tagline, "Transforming Spaces," perfectly encapsulates our mission to turn 
    ordinary rooms into extraordinary experiences. */}
    <br />
    <br />
    
    {/* Even our logo holds a special meaning. It is designed as a symbolic door, representing the
     threshold between ordinary and extraordinary, and embodying our commitment to crafting 
     stunning interiors that transcend expectations. */}
                </article>
                <figure><img src={projectImg[4]} alt="" /></figure>
            </section>
            <section className="about-sec3">
            <div className="our-approach">
                Our Approach
            </div>
                <p>
                {/* Intor Interiors and contracting has been dedicated to delivering excellence, innovation,
                 and unparalleled designs to our valued clients. We believe in the power of interior spaces 
                 to enhance lives, evoke emotions, and create lasting impressions. With our team of talented
                  designers and craftsmen, we strive to bring our clients' visions to life while exceeding their expectations. */}
                  {data.ourApproach1}
                  <br />
                {/* At Intor Interiors and contracting , we are driven by a deep passion for design, an unwavering commitment to quality,
                and a relentless pursuit of perfection. We are honored to collaborate with clients on their interior projects, and we
                look forward to transforming spaces and creating extraordinary environments that leave a lasting impression. */}
                  {data.ourApproach2}
                </p>
                <Slider/>
            </section>
            <section className='about-sec4'>
                <h1 style={{overflow:'hidden'}}>
                <Inview delay={0.1} animation={slideUp}>
                LET’S START <br />PLANNING
                </Inview >
                </h1>
                <div style={{border:'1px solid #000',borderWidth:'1px 0 0'}}><HorizontalBtn path='/contact' content="Get in Touch" logo={<FaArrowRight />}/></div>
            </section>
        </main>
        </>
    )
}
export default About;