import { Link } from 'react-router-dom';
import Inview from '../Inview';
// import './horizontalBtn.css'
const HorizontalBtn = ({content,logo,color,path}) =>{
    const slidel = {
        initial:{x:-100}
        ,final:{x:0}
    }
    return(
        <>
        <Inview classname={`horizontal-btn`} animation={slidel}>
                <Link  onClick={()=>{window.scrollTo(0,0)}} to={path} className={`first-btn ${color}`}> {content}</Link>
                <Link  onClick={()=>{window.scrollTo(0,0)}} to={path} className={`second-btn ${color}`}> {logo} </Link>
            </Inview>
        </>
    )
}
export default HorizontalBtn;