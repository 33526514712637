// import './css/index.css'
import video from './img/file.mp4'
import pic from './img/img2.webp'
import pic2 from './img/img1.webp'
import img from './img/female.webp'
// import int from './img/int.png'
import int from './img/sl3.jpg'
import int2 from './img/int2.avif'
import int3 from './img/int3.avif'
import int4 from './img/int4.avif'
import int5 from './img/sl5.jpg'
// import int5 from './img/int5.avif'
import int6 from './img/int6.jpg'
// import int6 from './img/int6.jpg'
import int7 from './img/int7.webp'
import int8 from './img/int8.webp'
import int9 from './img/int9.webp'
// import flight from './img/flight-bg.jpg'
import svg from './img/svg.png'
import Animation from './Animation'
import Inview from './Inview'
import HorizontalBtn from './components/HorizontalBtn'
import SendMessage from './components/SendMessage'
import {FaArrowRight } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { data, projectImg } from './data'

export const slide = {
    initial:{x:-100}
    ,final:{x:0}
}

export const slideUp = {
    initial:{y:100}
    ,final:{y:0}
}
export const fade={
    initial:{opacity:0},
    final:{opacity:1},
}
const imgSrc = [
        {id:1,src:projectImg[0]},{id:2,src:projectImg[1]},{id:3,src:projectImg[2]},
        {id:4,src:projectImg[3]},{id:5,src:projectImg[4]},{id:6,src:projectImg[5]},{id:7,src:projectImg[6]},{id:8,src:projectImg[7]}]
const Home = () =>{
    return(
        <>
        <main>
        <section className='section-1' style={{height:'calc((100vh - 100px)*90/100)',border:'1px solid #000',boxSizing:'border-box'}}>
        <video src={video} 
             style={{height: "100%",width: '100%', objectFit: 'cover', objectPosition: "center center", opacity: 1,zIndex:10}} autoPlay muted loop>

             </video>
             <sub style={{overflow:'hidden'}}>
                <div className="primary-content" >
               <Animation d={3}> {data.hero.heading1}</Animation> <Animation d={3.2}>  {data.hero.heading2}</Animation>
                
                <Animation d={3.3}><p style={{}}>
                {/* welcome to the world of intor interiors and contracting */}
                {data.hero.content}
                </p></Animation>
                </div>
             </sub>
            </section>
            <hr className='white'/>
            <section className='section-2'>
                <Inview animation={slide} classname={"get-start"}>
                {/* <div className="get-start"> */}
                        <a className='gs1'> <Link  onClick={()=>{window.scrollTo(0,0)}}
                         style={{textDecoration:'none',color:'inherit'}} to='/contact'>Get Started</Link></a>
                        <a className='gs2'>  <Link  onClick={()=>{window.scrollTo(0,0)}}
                         style={{textDecoration:'none',color:'inherit'}} to='/contact'><FaArrowRight /></Link></a>
                {/* </div> */}
                </Inview>
                <div className="second"></div>
                <div className="third">
                    <img src={projectImg[7]} alt="" />
                </div>
                <div className="fourth">
                    <Inview animation={slide} delay={0}>
                    <img src={projectImg[10]} alt="" />
                    </Inview>
                </div>
                <div className="five">
                {/* Intor Interiors and Contracting
is a multi-disciplinary design studio dealing with projects across multiple areas, 
from interior design for high-end residential homes and commercial spaces */}
{data.secondInHome}
                </div>

                <div className="dummy-1"></div>
                <div className="dummy-2"></div>
            </section>
            <section className='section-3'>
                <li><Inview animation={fade} delay={.1}>Our Expertise</Inview></li>
                <li><Inview animation={fade} delay={.2}>DESIGN AND BUILD</Inview></li>
                <li><Inview animation={fade} delay={.3}>RESIDENTIAL AND <br />COMMERCIAL INTERIORS</Inview></li>
                <li><Inview animation={fade} delay={.4}>CUSTOMISED RENOVATIONS</Inview></li>
                <li> <a> <Link  onClick={()=>{window.scrollTo(0,0)}}  style={{textDecoration:'none',color:'inherit'}} to='/about'> Learn More </Link> </a> </li>
            </section>
            <Inview animation={fade} delay={.5} classname={"section4"}>
            {/* <a className='gs2 get-start'> @</a> */}
            <a className='sec4-btn'> <Link  onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}} to='/about'><FaArrowRight /></Link></a>
            <img src={svg} alt="" className='sec4-im1'/>
               <div className="cor-68 sec4-h1">
               Making your space <br />beautifully efficient
               </div>
            <img src={svg} alt="" className='sec4-im2' />
            </Inview>
            <section className='section5'>
                <img src={projectImg[4]} alt="" />
                <aside>
                    <p>
                    {/* As the founder of Intor Interiors, I, Lizabeth Joseph, have always been passionate 
                    about transforming spaces and creating beautiful interiors. Prior to establishing 
                    Intor in 2015, I honed my skills and gained valuable experience working with renowned 
                    companies such as Woodlife Interiors, Donna Rossi Modular Kitchen and Wardrobes, and
                    IKian Furniture's. */}
                    {data.founder1}
                    </p>
                    <p>
                    {/* After years of dedication and building expertise in the industry, I realized that 
                    I wanted to work for myself and bring my unique vision to life. I wanted the freedom
                    to explore my creative ideas and create stunning interiors without limitations. This
                    desire to forge my own path led me to start the brainchild of Intor Interiors and 
                    contracting. */}
                    {data.founder2}
                    </p>
                </aside>
                    <div className="wrap-btn">
                    <div className="sec5-btn1"><Link  onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}} to='/about'>Know More</Link></div>
                <div className="btn"><Link  onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}} to='/about'><FaArrowRight /></Link></div>
                    </div>
            </section>
            <section className='section6'>
                <Inview animation={fade} delay={.2} classname={'koko'}>
                <h1>Favorite Project</h1>
                </Inview>
                <div className="img-grid">
                    {imgSrc.map((e,i)=>{
                        return(
                            <Inview animation={slideUp} classname={`in ${'i'+e.id}`} key={i}><img src={e.src}/></Inview>
                        )
                    })}
                    {/* <div className=" in i1"></div>
                    <div className=" in i2"></div>
                    <div className=" in i3"></div>
                    <div className=" in i4"></div>
                    <div className=" in i5"></div>
                    <div className=" in i6"></div>
                    <div className=" in i7"></div> */}
                </div>
            </section>
            <HorizontalBtn path='/portfolio' content="Our Portfolio" color="" logo={<FaArrowRight />}/>
                <section className='section7'>
                    <div style={{overflow:'hidden'}}><Inview animation={slideUp} delay={.4} classname={"sec7-h1"} > LET’S START PLANNING </Inview ></div>
                    <SendMessage/>
                </section>
            <HorizontalBtn path="/contact" content="Get In Touch" color="" logo={<FaArrowRight />}/>
                <br /><br /><br /><br /><br /><br />
        </main>
        </>
        )
}
export default Home;