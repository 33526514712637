// import './sendmessage.css'
// const SendMessage = () =>{
//     return(
//         <>
//         <form className="home-form">
//             <p>Send us a message
//                 <br />
// and we’ll get back to you shortly.</p>
//             <label htmlFor="">Email</label>
//             <input type="Email" />
//             <label htmlFor="">Phone Number</label>
//             <input type="text" />
//             <label htmlFor="">Your Message</label>
//             <input type="text" />
//             <button>Send</button>
//         </form>
//         </>
//     )
// }
// export default SendMessage;
import React, { useState } from 'react';
import * as Yup from 'yup';
import './sendmessage.css';
import axios from 'axios';
import { url } from '../data';

const SendMessage = () => {
  // Define state variables for form data and errors
  const [formData, setFormData] = useState({
    email: '',
    phoneNumber: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    phoneNumber: Yup.string().required('Phone number is required').min(10,'Must be 10 digits').max(10,'Must be 10 digits'),
    message: Yup.string().required('Message is required')
  });

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true)
      // Validate form data
      await validationSchema.validate(formData, { abortEarly: false });
      // Form data is valid, handle form submission (e.g., send data to server)
      setFormData(prv => prv={
        email: '',
        phoneNumber: '',
        message: ''
      })
     console.log('Form submitted successfully:', formData);
     let email = await axios.post(url,{...formData})
     setEmailSent(true);
     console.log(email);
    } catch (validationErrors) {
      // Form data is invalid, set errors state with validation errors
      const errors = {};
      validationErrors.inner.forEach(error => {
        errors[error.path] = error.message;
      });
      setErrors(errors);
    }
    finally{
      setLoading(false);
      setTimeout(()=>{setEmailSent(false)},5000)
    }
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error message when user starts typing again
    if (errors[name]) {
      setErrors({ ...errors, [name]: '' });
    }
  };

  return (
    <>
      <form className="home-form" onSubmit={handleSubmit}>
        <p>Send us a message<br />and we’ll get back to you shortly.</p>
        <label htmlFor="email">Email</label>
        <input type="email" name="email" value={formData.email} onChange={handleInputChange} />
        {errors.email && <span className="error">{errors.email}</span>}
        <label htmlFor="phoneNumber">Phone Number</label>
        <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleInputChange} />
        {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
        <label htmlFor="message">Your Message</label>
        <input type="text" name="message" value={formData.message} onChange={handleInputChange}/>
        {errors.message && <span className="error">{errors.message}</span>}
        <button type="submit"> {loading?"loading...":`${emailSent?'Sended':'Send'}`}</button>
      </form>
    </>
  );
};

export default SendMessage;
