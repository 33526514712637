// import './css/end.css';
import { Link } from 'react-router-dom';
import logo from './img/log.webp';
const End = () => {
    return (
      <>
     <div class="foter">

    <Link to='/' onClick={()=>{window.scrollTo(0,0)}}  style={{textDecoration:'none',color:'inherit'}}> 
    {/* <img src={logo} class="logo1" alt="Logo"  /> */}
    </Link>
     <div class="lg">
     {/* <p class="lg1">Email · intorinteriors@gmail.com</p> */}
                    <p class="lg1">Phone : 8608310797 , 9751172355</p>
                    <p class="lg1">Address : 355/1, Sri Kannapiram Complex,</p>
                    <p class="lg4">  Ayyavu Devar Nagar,Kannanathal,</p>
                    <p class="lg4">  IyarBanglaw, Madurai-14</p>
        </div>
        <div class="lg">
        <p class="lg2"><Link to='/' onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}}>Home </Link></p>
        <p class="lg2"><Link to='/about' onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}}>About </Link></p>
        <p class="lg2"><Link to='/portfolio' onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}}>Portfolio </Link></p>

                    {/* <p class="lg2"><Link to='/services' onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}}>Services​ </Link></p> */}
                    <p class="lg2"><Link to='/contact' onClick={()=>{window.scrollTo(0,0)}} style={{textDecoration:'none',color:'inherit'}}>Contact </Link></p>
        </div>


     </div>

      </>
 
      
    );
 }
 
 export default End;